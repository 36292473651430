<template>
  <main class="h-[70vh] max-w-xl mx-auto flex items-center justify-center sm:h-[80vh]">
    <div class="flex-1 px-4 sm:px-6">
      <h1 class="text-4xl font-heading text-center">Crée un nouveau mot de passe</h1>
      <DisplayError :errors="errors" class="text-center mt-3" />

      <form class="mt-8 space-y-4" @submit.prevent="onSubmit">
        <AppInput id="password" type="password" label="Mot de passe" />
        <AppInput id="password2" type="password" label="Confirmez le mot de passe" />
        <AppButton
          class="w-full bg-primary text-white hover:ring-red-200 focus:ring-red-200"
          :loading="loading || captchaLoading"
          type="submit"
        >
          Réinitialiser
        </AppButton>
      </form>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent, onBeforeUnmount, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useMeta } from 'vue-meta';
import { useReCaptcha } from 'vue-recaptcha-v3';

import { resetPasswordMetaTags } from '@/lib/metaTags';
import AppButton from '@/components/shared/AppButton.vue';
import AppInput from '@/components/shared/AppInput.vue';
import DisplayError from '@/components/shared/DisplayError.vue';
import useAuth from '@/hooks/auth/use-auth';
import useCart from '@/hooks/cart/use-cart';
import useToast from '@/hooks/use-toast';

export default defineComponent({
  components: { AppInput, AppButton, DisplayError },
  setup() {
    useMeta(resetPasswordMetaTags());
    const router = useRouter();
    const { query } = useRoute();
    const captchaLoading = ref(true);
    const captcha = useReCaptcha();
    const { showToast } = useToast();
    const { useResetPassword } = useAuth();
    const { resetPassword, loading, errors } = useResetPassword();
    const { getCartItems } = useCart().useGetCartItems();

    const onSubmit = async (e: Event) => {
      const { elements } = e.target as HTMLFormElement;
      const password = (elements.namedItem('password') as HTMLInputElement).value;
      const passwordConfirmation = (elements.namedItem('password2') as HTMLInputElement).value;

      captchaLoading.value = true;
      const captchaToken = await captcha?.executeRecaptcha('submit');

      if (!captchaToken) {
        captchaLoading.value = false;
        showToast({ message: 'Échec du test reCAPTCHA', type: 'failure' });
        return;
      }

      await resetPassword({
        password,
        passwordConfirmation,
        code: query.code as string,
        captchaToken,
      });

      captchaLoading.value = false;

      if (!errors.value.length) {
        (e.target as HTMLFormElement).reset();

        getCartItems();

        router.push({ name: '/' });
      }
    };

    onMounted(async () => {
      try {
        captchaLoading.value = true;

        await captcha?.recaptchaLoaded();
        captcha?.instance.value?.showBadge();
      } catch {
        showToast({ message: 'Échec du test reCAPTCHA', type: 'failure' });
      } finally {
        captchaLoading.value = false;
      }
    });

    onBeforeUnmount(() => {
      captcha?.instance.value?.hideBadge();
    });

    return {
      errors,
      loading,
      captchaLoading,
      onSubmit,
    };
  },
});
</script>
